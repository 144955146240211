// client/src/components/About.js
import React from 'react';

function About() {
  return (
    <div>

      <h1>Test Webapp</h1>

    </div>
  );
}

export default About;
