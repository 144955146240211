// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  z-index: 1;
  width: 100%;
  background-color: #90ee90;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
  
  .navbar-logo a {
    color: rgb(255, 81, 0);
    text-decoration: none;
    font-size: 24px;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
  }
  
  .navbar-links li {
    margin: 0 10px;
    color: blue;
  }
  
  .navbar-links a {
    color: rgb(15, 123, 211);
    text-decoration: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/NavBar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,8BAA8B;EAC9B,aAAa;AACf;;EAEE;IACE,sBAAsB;IACtB,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,cAAc;IACd,WAAW;EACb;;EAEA;IACE,wBAAwB;IACxB,qBAAqB;EACvB","sourcesContent":[".navbar {\n  z-index: 1;\n  width: 100%;\n  background-color: #90ee90;\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n}\n  \n  .navbar-logo a {\n    color: rgb(255, 81, 0);\n    text-decoration: none;\n    font-size: 24px;\n  }\n  \n  .navbar-links {\n    list-style: none;\n    display: flex;\n  }\n  \n  .navbar-links li {\n    margin: 0 10px;\n    color: blue;\n  }\n  \n  .navbar-links a {\n    color: rgb(15, 123, 211);\n    text-decoration: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
